<template>
  <div>
    <van-nav-bar
      title="情绪日记"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="diary">
      <!-- 今日天气 -->
      <div class="weather">
        <div class="ctx-title">
          <div class="title-ctx">今日天气</div>
          <div class="title-fk"></div>
        </div>

        <div class="tianqi">
          <div
            v-for="(weather, index) in weatherItems"
            :key="index"
            :class="['tianqiitem', { tianqiitemactive: index == activeIndex }]"
            @click="handleClick(index)"
          >
            <img class="img" :src="weather.imageSrc" :alt="weather.alt" />
            <div>{{ weather.text }}</div>
          </div>
        </div>
      </div>

      <!-- 情绪坐标 -->

      <div class="coordinates">
        <div class="sevenxy">
          <div class="ctx-title">
            <div class="title-ctx">今日情绪坐标</div>
            <div class="title-fk"></div>
          </div>
          <img
            @click="tosevenDay"
            src="https://lesson.iapeap.com/images/EmotionalValue/sevenDay.png"
            alt=""
          />
        </div>

        <div class="zuobiao" ref="zuobiaoBox" @click="toggleStar($event)">
          <!-- zuobiao盒子的现有内容 -->
          <div class="dot-container">
            <!-- 在这里添加五角星 -->
            <img
              v-if="showStar"
              ref="starElement"
              class="star"
              src="https://lesson.iapeap.com/images/EmotionalValue/wujiaoxing.png"
              alt="五角星"
              width="14"
              height="14"
              :style="{
                left: starX - 7 + 'px',
                top: starY - 7 + 'px',
                position: 'relative',
              }"
            />
          </div>
        </div>
        <div class="wenxintishi">
          <div class="qingxuzuobiao">
            <img
              src="https://lesson.iapeap.com/images/EmotionalValue/gantanhao.png"
              alt=""
            />
            <div>温馨提示：</div>
          </div>
          <div class="wenxintishi-ctx2">
            1.你可以根据自己“愉快”或“不愉快”的感受，以及体验到的情绪强度来选择自己的情绪坐标
          </div>
          <div class="wenxintishi-ctx2">
            ⒉.你也可以参考图中一些常见情绪的“坐标”
          </div>
          <div class="wenxintishi-ctx2">
            3.你可以使用这个情绪坐标作为记录和觉察自己情绪的工具，在7天之后看到自己这一周情绪的“坐标分布”，了解自己的情绪状态
          </div>
        </div>
      </div>

      <!-- 今日体验 -->
      <div class="jinritiyan">
        <div class="ctx-title">
          <div class="title-ctx">今日体验</div>
          <div class="title-fk"></div>
        </div>
        <div v-html="formattedText"></div>
        <br />
        <div>
          {{ comment || "" }}
        </div>
        <div class="imglist">
          <div v-for="(imgitem, imgindex) in img_url" :key="imgindex">
            <img :src="imgitem" />
          </div>
        </div>
      </div>

      <div style="margin-top: 10px">
        <van-checkbox v-model="onlyme" shape="square"
          ><span
            style="
              height: 14px;
              font-size: 10px;
              font-weight: 500;
              color: #629ee9;
              line-height: 14px;
              letter-spacing: 1px;
            "
            >仅自己可见</span
          ></van-checkbox
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  uploadFile,
  saveEmoDiary,
  setComplated,
  getEmoDiary,
  memberLog,
} from "../../api/login";
export default {
  data() {
    return {
      id: "0",
      fileList: [],
      imglist: [],
      showStar: true, // 用于切换是否显示五角星
      starX: 0, // 五角星的X坐标
      starY: 0, // 五角星的Y坐标
      weatherItems: [
        {
          imageSrc:
            "https://lesson.iapeap.com/images/EmotionalValue/tianqi1.png",
          alt: "",
          text: "晴",
        },
        {
          imageSrc:
            "https://lesson.iapeap.com/images/EmotionalValue/tianqi2.png",
          alt: "",
          text: "多云",
        },
        {
          imageSrc:
            "https://lesson.iapeap.com/images/EmotionalValue/tianqi3.png",
          alt: "",
          text: "小雨",
        },
        {
          imageSrc:
            "https://lesson.iapeap.com/images/EmotionalValue/tianqi4.png",
          alt: "",
          text: "大雨",
        },
        {
          imageSrc:
            "https://lesson.iapeap.com/images/EmotionalValue/tianqi5.png",
          alt: "",
          text: "雪",
        },
      ],
      activeIndex: 6,
      weather: 0,
      comment: "",
      onlyme: false,
      offsetX: 0,
      offsetY: 0,
      task_info: "",
      img_url: [],
      days: "",
      starttime: "",
      endtime: "",
      time: "",
    };
  },
  mounted() {
    if (this.$route.query.item.id) {
      this.id = this.$route.query.item.id;
      this.task_info = this.$route.query.item.task_info;
      setTimeout(() => {
        window.localStorage.setItem(
          "task_info",
          this.$route.query.item.task_info
        );
        window.localStorage.setItem("id", this.$route.query.item.id);
      }, 1000);
    } else {
      this.id = window.localStorage.getItem("id");
      this.task_info = window.localStorage.getItem("task_info");
    }

    this.timer = setInterval(() => {
      this.time++;
      // console.log("页面累积浏览时间是：",this.time,"秒")
    }, 1000); // 每秒触发一次
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    this.starttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("进入页面的系统时间是：", this.starttime);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("离开页面的系统时间是", this.endttime);

    //把参数传给后端接口，starttime、endttime、time
    let data = {
      startTime: this.starttime,
      endTime: this.endttime,
      userId: window.localStorage.getItem("userId"),
      pageName: "查看情绪日记",
      timeStamp: this.time,
    };
    memberLog(data).then((res) => {});
  },

  computed: {
    formattedText() {
      return this.task_info.replace(/\r\n/g, "<br>");
    },
  },
  created() {
    this.days = window.localStorage.getItem("day");
    setTimeout(() => {
      this.getgetEmoDiary();
    }, 500);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getgetEmoDiary() {
      console.log(this.id);
      let data = {
        id: this.id,
        userId: window.localStorage.getItem("userId"),
      };
      getEmoDiary(data).then((res) => {
        this.activeIndex = res.data.weather;
        this.comment = decodeURIComponent(res.data.comment);
        this.img_url = res.data.img_url;
        console.log(this.img_url);

        if (res.data.onlyme == 0) {
          this.onlyme = false;
        } else {
          this.onlyme = true;
        }

        this.starX = Number(res.data.emo_coordinate[0]);
        this.starY = Number(res.data.emo_coordinate[1]);
        console.log(this.starX, this.starY);
      });
    },
    tosevenDay() {
      this.$router.push("/emotion/SevenDay");
    },
  },
};
</script>

<style lang="less">
.navbar {
  background-color: #ededed;
}
/deep/ .van-nav-bar .van-icon {
  color: #464646;
}
body {
  padding-top: env(safe-area-inset-top);
  margin-top: -env(safe-area-inset-top);
}
.diary {
  background-color: #f4fafe;
  min-height: 100vh;
  padding: 40px 20px;
  box-sizing: border-box;
  .weather {
    height: 149px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box;
    .tianqi {
      display: flex;
      font-size: 7px;
      font-weight: 300;
      color: #becee3;
      margin-top: 10px;
      .tianqiitem {
        width: 72px;
        padding: 5px 0;
        text-align: center;
        box-sizing: border-box;
        .img {
          width: 42px;
          height: 42px;
        }
      }
      .tianqiitemactive {
        border: 1px solid #55a4f0;
        border-radius: 15px;
      }
    }
  }
  .coordinates {
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    .sevenxy {
      display: flex;
      justify-content: space-between;
      img {
        width: 23px;
        height: 20px;
      }
    }
    .zuobiao {
      margin-top: 10px;
      height: 298px;
      background-image: url("https://lesson.iapeap.com/images/EmotionalValue/zuobiao.png");
      background-size: 100% 100%;
    }
    .wenxintishi {
      margin-top: 10px;
      .qingxuzuobiao {
        display: flex;
        align-items: center;
        gap: 5px;
        img {
          width: 12px;
        }
      }
      .wenxintishi-ctx2 {
        padding: 0 10px;
        font-size: 12px;
        margin-bottom: 8px;
        color: #a09c9b;
        box-sizing: border-box;
      }
    }
  }
  .jinritiyan {
    margin-top: 20px;
    // height: 420px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
  }
  .tiyanbianji {
    width: 100%;
    height: 168px;
    border-radius: 8px;
    border: 2px solid #becfe3;
    padding: 15px 25px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  .shurukuang {
    width: 15px;
    height: 15px;
    position: relative;
    top: 38px;
    left: 10px;
  }
  .tianjia {
    width: 88px;
    height: 88px;
    border-radius: 8px;
    border: 1px solid #becfe3;
    line-height: 88px;
    text-align: center;
    color: #becfe3;
    font-size: 50px;
  }
  .ctx-title {
    .title-ctx {
      z-index: 999;
      position: relative;
      font-size: 18px;
      font-weight: 500;
      color: #000025;
      line-height: 25px;
    }
    .title-fk {
      width: 35px;
      height: 5px;
      background: #629ee9;
      position: relative;
      bottom: 8px;
      z-index: 998;
      transform: skew(-25deg);
    }
  }
  .diary-btn {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    button {
      width: 200px;
      height: 40px;
      color: #fff;
      border: 0;
      border-radius: 20;
      background-color: #55a4f0;
      border-radius: 25px;
    }
  }
  .imglist {
    display: flex;
    margin-top: 20px;
    // justify-content: center;
    gap: 20px;
    flex-wrap: wrap;

    img {
      width: 80px;
      height: 80px;
    }
  }
}
</style>
